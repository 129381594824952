import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ThemeProvider
} from "@mui/material";
import Report from "assets/icons/estate_info/tool_report.svg";
import { DialogHeader } from "components/Parts/Atoms/DialogHeader";
import { Confirm } from "components/Parts/Molecules/Confirm";
import { NoticeDialog } from "components/Parts/Molecules/NoticeDialog";
import { ProgressBackdrop } from "components/Parts/Molecules/ProgressBackdrop";
import { isEmpty } from "lodash";
import React from "react";
import styles from "./ReportInputDialog.module.scss";
import {
  DescriptionField,
  InfoField,
  LocationField,
  ReportKindField
} from "./fields";
import { useReportInputDialogHooks } from "./hooks";
import { ReportInputDialogProps } from "./interfaces";

const idPrefix = "company-input-dialog";

export const ReportInputDialog = React.memo((props: ReportInputDialogProps) => {
  const hooks = useReportInputDialogHooks(props);

  const {
    theme,

    estate,
    report,

    specifyMessage,

    reportKinds,

    location,
    center,
    zoom,
    places,
    address,

    errorMessage,

    busy,
    forceDisable,
    confirm,
    thanks,

    onReportKindChange,
    onDescriptionChange,

    onAddressChange,
    onAddressToMapButtonClick,

    onMapChange,
    onMapReady,

    onLocationChange,

    onOkButtonClick,
    onClose,
    onConfirmClick,
    onConfirmCloseClick,
    onConfirmClose,
    onThanksCloseClick,
    onThanksClose
  } = hooks;

  if (props.open) {
    return (
      <Dialog
        open={props.open}
        onClose={onClose}
        aria-labelledby={`${idPrefix}-title`}
        aria-describedby={`${idPrefix}-description`}
        maxWidth={false}
        disableEscapeKeyDown
        PaperProps={{ className: styles.dialogPaper }}
        slotProps={{
          backdrop: { className: styles.backdrop }
        }}
        componentsProps={{
          root: { className: styles.root }
        }}
      >
        <DialogHeader
          id={`${idPrefix}-title`}
          icon={
            <Box className={styles.icon}>
              <Box component="img" src={Report} />
            </Box>
          }
        />
        <DialogContent
          id={`${idPrefix}-description`}
          className={styles.dialogContent}
        >
          <Collapse in={props.errorMessage !== ""}>
            <Alert severity="error" className={styles.alert}>
              <AlertTitle>エラーが発生しました！</AlertTitle>
              {props.errorMessage}
            </Alert>
          </Collapse>
          <Collapse in={!isEmpty(errorMessage)}>
            <Alert severity="error" className={styles.alert}>
              <AlertTitle>入力エラーがあります。</AlertTitle>
              {errorMessage}
            </Alert>
          </Collapse>
          <ThemeProvider theme={theme}>
            <Grid container>
              <Grid item xs={12}>
                <InfoField estate={estate} />
              </Grid>
              <Grid item xs={12}>
                <Box className={styles.separator} />
              </Grid>
              <Grid item xs={12}>
                <ReportKindField
                  reportKinds={reportKinds}
                  report={report}
                  busy={busy}
                  forceDisable={forceDisable}
                  onReportKindChange={onReportKindChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Box className={styles.separator} />
              </Grid>
              <Grid item xs={12}>
                <DescriptionField
                  report={report}
                  busy={busy}
                  forceDisable={forceDisable}
                  onDescriptionChange={onDescriptionChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Box className={styles.separator} />
              </Grid>
              <Grid item xs={12}>
                <Box className={styles.map}>
                  <LocationField
                    estate={estate}
                    location={location}
                    center={center}
                    zoom={zoom}
                    places={places}
                    address={address}
                    busy={busy}
                    forceDisable={forceDisable}
                    onAddressChange={onAddressChange}
                    onAddressToMapButtonClick={onAddressToMapButtonClick}
                    onMapChange={onMapChange}
                    onMapReady={onMapReady}
                    onLocationChange={onLocationChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </ThemeProvider>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button
            variant="contained"
            className={styles.cancelButton}
            disabled={busy || forceDisable}
            onClick={props.onClose}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            className={styles.okButton}
            variant="contained"
            disabled={busy || forceDisable}
            onClick={onOkButtonClick}
          >
            {props.okButtonCaption}
          </Button>
        </DialogActions>
        <Box className={styles.bottomError}>{specifyMessage}</Box>
        <ProgressBackdrop open={busy || props.busy || false} />
        <Confirm
          open={confirm}
          content="報告を送信します。"
          onClose={onConfirmClose}
          onCloseClick={onConfirmCloseClick}
          onOkClick={onConfirmClick}
        />
        <NoticeDialog
          open={thanks}
          content={"ご協力ありがとうございました。"}
          onClose={onThanksClose}
          onCloseClick={onThanksCloseClick}
        />
      </Dialog>
    );
  } else {
    return null;
  }
});
