import { useMediaQuery } from "@mui/material";
import { TokyoCoords } from "common/consts/googleMaps";
import { MainContextContainer } from "components/Main";
import { MainWrapper } from "components/Parts/Atoms/MainWrapper";
import { BackgroundMap } from "components/Parts/Molecules/BackgroundMap";
import { ContentMain } from "components/Parts/Organisms/ContentMain";
import { HeaderBar } from "components/Parts/Organisms/HeaderBar";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RequestInputDialog } from "../RequestInputDialog";
import { RequestInputMode } from "../RequestInputDialog/enums";
import styles from "./HomePage.module.scss";
import { HomeProps } from "./interfaces";
import { LargeHomePageContent } from "./LargeHomePageContent";
import { MiddleHomePageContent } from "./MiddleHomePageContent";
import { SmallHomePageContent } from "./SmallHomePageContent";

export const HomePage = React.memo((props: HomeProps) => {
  const mainContext = useContext(MainContextContainer);
  const isLarge = useMediaQuery("(min-width:1350px)");
  const isMiddle = useMediaQuery("(min-width:880px)");

  const navigate = useNavigate();

  const [requestInputDialogOpen, setRequestInputDialogOpen] = useState(false);

  const onRequestInputDialogOpen = useCallback(() => {
    setRequestInputDialogOpen(true);
  }, []);

  const onRequestInputDialogClose = useCallback(() => {
    setRequestInputDialogOpen(false);
  }, []);

  useEffect(() => {
    mainContext.setLogoVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentMain>
      <HeaderBar
        authStatus={mainContext.authStatus}
        authUser={mainContext.authUser}
        user={mainContext.user}
        company={mainContext.company}
        userInfo={mainContext.userInfo}
        groups={mainContext.groups}
      />
      <MainWrapper>
        {mainContext.company?.latitude && mainContext.company?.longitude && (
          <BackgroundMap
            center={TokyoCoords}
            mapDivId="background-map"
            location={{
              lat: mainContext.company?.latitude,
              lng: mainContext.company?.longitude
            }}
            zoom={5}
            onMapReady={() => {}}
            className={styles.backgroundMap}
          />
        )}
        {isLarge ? (
          <LargeHomePageContent
            {...props}
            mainContext={mainContext}
            navigate={navigate}
            onRequestInputDialogOpen={onRequestInputDialogOpen}
          />
        ) : isMiddle ? (
          <MiddleHomePageContent
            {...props}
            mainContext={mainContext}
            navigate={navigate}
            onRequestInputDialogOpen={onRequestInputDialogOpen}
          />
        ) : (
          <SmallHomePageContent
            {...props}
            mainContext={mainContext}
            navigate={navigate}
            onRequestInputDialogOpen={onRequestInputDialogOpen}
          />
        )}
        {requestInputDialogOpen ? (
          <RequestInputDialog
            open={requestInputDialogOpen}
            okButtonCaption="登録依頼"
            mode={RequestInputMode.Create}
            errorMessage=""
            onClose={onRequestInputDialogClose}
            onOkButtonClick={undefined}
          />
        ) : null}
      </MainWrapper>
    </ContentMain>
  );
});
