import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  TextField
} from "@mui/material";
import { Estate, Report } from "API";
import Pin from "assets/icons/estate_location/pin.svg";
import Apartment from "assets/icons/ic_map_type_apartment.svg";
import Block from "assets/icons/ic_map_type_block.svg";
import Business from "assets/icons/ic_map_type_business.svg";
import Ground from "assets/icons/ic_map_type_ground.svg";
import New from "assets/icons/ic_map_type_new.svg";
import Used from "assets/icons/ic_map_type_used.svg";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { ReportKind } from "common/interfaces/ReportKind";
import { JsonMasterSwitchButtons } from "components/Parts/Atoms/JsonMasterSwitchButtons";
import { ChangeEventValue } from "components/Parts/Atoms/SumoraMap/interfaces";
import { LocationMap } from "components/Parts/Molecules/LocationMap";
import { isEmpty } from "lodash";
import { memo, useMemo } from "react";
import styles from "./ReportInputDialog.module.scss";

const LocationMapWithInfo = memo(
  (props: {
    estate: Estate;
    location: google.maps.LatLngLiteral | null;
    center: google.maps.LatLngLiteral;
    zoom: number;
    places: google.maps.places.PlaceResult | undefined;
    busy: boolean;
    onMapChange: (value: ChangeEventValue) => void;
    onMapReady: (map: google.maps.Map) => void;
    onLocationChange: (value: google.maps.LatLng) => void;
  }) => {
    const {
      estate,
      location,
      center,
      zoom,
      places,
      busy,
      onMapChange,
      onMapReady,
      onLocationChange
    } = props;

    const isCondominium = useMemo(
      () => estate.estateType === EstateTypeNameEnum.マンション,
      [estate.estateType]
    );

    return (
      <Grid container className={styles.mapWrapper}>
        <Grid item xs={12}>
          <LocationMap
            mapDivId="location-map"
            location={location}
            center={center}
            zoom={zoom}
            places={places}
            draggable={!busy && !isCondominium}
            onChange={onMapChange}
            onMapReady={onMapReady}
            onLocationChange={onLocationChange}
            className={styles.map}
          />
        </Grid>
        <Grid item xs={12}>
          <small>
            緯度: {estate.latitude} / 経度: {estate.longitude}
          </small>
        </Grid>
        <Grid item xs={12}>
          <small>
            都道府県: {estate.prefecture || "？"} / 市区町村:{" "}
            {estate.city || "？"} / 町域: {estate.area || "？"}
          </small>
        </Grid>
      </Grid>
    );
  }
);

export const InfoField = memo((props: { estate: Estate }) => {
  const { estate } = props;

  return (
    <Box className={styles.bkn_info}>
      <Box className={styles.box}>
        <Box className={styles.icon}>
          <Box
            component="img"
            src={
              estate.estateType === EstateTypeNameEnum.土地
                ? Ground
                : estate.estateType === EstateTypeNameEnum.分譲地
                ? Block
                : estate.estateType === EstateTypeNameEnum.新築建売
                ? New
                : estate.estateType === EstateTypeNameEnum.中古戸建
                ? Used
                : estate.estateType === EstateTypeNameEnum.マンション
                ? Apartment
                : estate.estateType === EstateTypeNameEnum.事業用
                ? Business
                : undefined
            }
          />
        </Box>
        <Box className={styles.dtl}>
          <Box className={styles.box}>
            <Box className={styles.tit}>ID（物件番号）</Box>
            <Box className={styles.tx}>{estate.id}</Box>
          </Box>
          <Box className={styles.box}>
            <Box className={styles.tit}>物件名称</Box>
            <Box className={styles.tx}>{estate.name}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export const ReportKindField = memo(
  (props: {
    reportKinds: ReportKind[];
    report: Partial<Report>;
    busy: boolean;
    forceDisable: boolean;
    onReportKindChange: (value: ReportKind | null | undefined) => void;
  }) => {
    const { reportKinds, report, busy, forceDisable, onReportKindChange } =
      props;

    return (
      <JsonMasterSwitchButtons<ReportKind>
        id="report-kind"
        label="報告内容"
        master={reportKinds}
        value={report.reportKind}
        disabled={forceDisable || busy}
        onChange={onReportKindChange}
      />
    );
  }
);

export const DescriptionField = memo(
  (props: {
    report: Partial<Report>;
    busy: boolean;
    forceDisable: boolean;
    onDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }) => {
    const { report, busy, forceDisable, onDescriptionChange } = props;

    return (
      <FormControl fullWidth>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <TextField
              multiline
              rows={4}
              fullWidth
              disabled={forceDisable || busy}
              value={report.description || ""}
              onChange={onDescriptionChange}
              placeholder="内容を記入してください"
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const LocationField = memo(
  (props: {
    estate: Estate;
    location: google.maps.LatLngLiteral | null;
    center: google.maps.LatLngLiteral;
    zoom: number;
    places: google.maps.places.PlaceResult | undefined;
    address: string | null | undefined;
    busy: boolean;
    forceDisable: boolean;
    onAddressChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onAddressToMapButtonClick: (
      event: React.MouseEvent<HTMLButtonElement>
    ) => void;
    onMapChange: (value: ChangeEventValue) => void;
    onMapReady: (map: google.maps.Map) => void;
    onLocationChange: (value: google.maps.LatLng) => void;
  }) => {
    const {
      estate,
      location,
      center,
      zoom,
      places,
      address,
      busy,
      forceDisable,
      onAddressChange,
      onAddressToMapButtonClick,
      onMapChange,
      onMapReady,
      onLocationChange
    } = props;
    const isCondominium = useMemo(
      () => estate.estateType === EstateTypeNameEnum.マンション,
      [estate.estateType]
    );

    return (
      <FormControl fullWidth>
        <FormLabel className={styles.formLabel} id="location-label">
          物件の位置
          {isCondominium ? (
            <Box component="span" className={styles.warning}>
              {" "}
              （マンションの位置情報は報告できません）
            </Box>
          ) : (
            ""
          )}
        </FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.place}>
            <Box className={styles.field}>
              <TextField
                id="place-searcher"
                fullWidth
                disabled={forceDisable || busy || isCondominium}
                placeholder="〒、住所の一部等を入力"
                value={address}
                onChange={onAddressChange}
              />
            </Box>
            <FormControl>
              <Button
                disabled={
                  forceDisable ||
                  busy ||
                  isEmpty(estate.fullAddress) ||
                  isCondominium
                }
                variant="outlined"
                onClick={onAddressToMapButtonClick}
                className={styles.addressToMapButton}
              >
                <Box component="span" className={styles.buttonIcon}>
                  <Box component="img" src={Pin} />
                </Box>
                位置取得
              </Button>
            </FormControl>
          </Box>
          <Box component="ul" className={styles.ki_maru_list}>
            <Box component="li">ピンを動かして正しい位置を教えてください</Box>
          </Box>
          <LocationMapWithInfo
            location={location}
            center={center}
            zoom={zoom}
            places={places}
            busy={busy}
            estate={estate}
            onMapChange={onMapChange}
            onMapReady={onMapReady}
            onLocationChange={onLocationChange}
          />
        </Box>
      </FormControl>
    );
  }
);
