import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ThemeProvider
} from "@mui/material";
import Plane from "assets/icons/ic_plus.svg";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { DialogHeader } from "components/Parts/Atoms/DialogHeader";
import { Confirm } from "components/Parts/Molecules/Confirm";
import { NoticeDialog } from "components/Parts/Molecules/NoticeDialog";
import { ProgressBackdrop } from "components/Parts/Molecules/ProgressBackdrop";
import { isEmpty } from "lodash";
import React from "react";
import styles from "./RequestInputDialog.module.scss";
import {
  AttachesField,
  BldConditionTypeField,
  BuildingFloorsField,
  BuildingFloorsForBusinessField,
  CompletionMonthField,
  CurrentStatusField,
  EstatePriceField,
  EstateTypeField,
  FloorField,
  GroundTypeField,
  LayoutField,
  LocationField,
  ManagementFeeField,
  MansionNameField,
  MediationKindField,
  OtherCostField,
  ParkingField,
  PurposeBsField,
  RemarksField,
  RepairCostField,
  RoomField,
  SquareOrTsuboAreaField,
  StructureField,
  TotalAreaField
} from "./fields";
import { useRequestInputDialogHooks } from "./hooks";
import { RequestInputDialogProps } from "./interfaces";

const idPrefix = "company-input-dialog";

export const RequestInputDialog = React.memo(
  (props: RequestInputDialogProps) => {
    const hooks = useRequestInputDialogHooks(props);

    const {
      contentRef,
      filesRef,
      theme,

      estate,

      specifyMessage,

      location,
      center,
      zoom,
      places,

      errorMessage,

      busy,
      confirm,
      thanks,

      humanFileSize,

      onEstateChange,

      onAddressToMapButtonClick,

      onMapChange,
      onMapReady,

      onLocationChange,

      onOkButtonClick,
      onClose,
      onConfirmClick,
      onConfirmCloseClick,
      onConfirmClose,
      onThanksCloseClick,
      onThanksClose,

      setFatal
    } = hooks;

    const commonParams = { estate, busy, onEstateChange, setFatal };

    if (props.open) {
      return (
        <Dialog
          open={props.open}
          onClose={onClose}
          aria-labelledby={`${idPrefix}-title`}
          aria-describedby={`${idPrefix}-description`}
          maxWidth={false}
          disableEscapeKeyDown
          PaperProps={{ className: styles.dialogPaper }}
          slotProps={{
            backdrop: { className: styles.backdrop }
          }}
          componentsProps={{
            root: { className: styles.root }
          }}
        >
          <DialogHeader
            id={`${idPrefix}-title`}
            icon={
              <Box className={styles.icon}>
                <Box component="img" src={Plane} />
              </Box>
            }
          />
          <DialogContent
            id={`${idPrefix}-description`}
            className={styles.dialogContent}
            ref={contentRef}
          >
            <Collapse in={props.errorMessage !== ""}>
              <Alert severity="error" className={styles.alert}>
                <AlertTitle>エラーが発生しました！</AlertTitle>
                {props.errorMessage}
              </Alert>
            </Collapse>
            <Collapse in={!isEmpty(errorMessage)}>
              <Alert severity="error" className={styles.alert}>
                <AlertTitle>入力エラーがあります。</AlertTitle>
                {errorMessage}
              </Alert>
            </Collapse>
            <ThemeProvider theme={theme}>
              <Grid container columnSpacing={2}>
                <Grid xs={12} item>
                  <EstateTypeField {...commonParams} />
                </Grid>
                <Grid xs={4} item>
                  <MediationKindField {...commonParams} />
                </Grid>
                <Grid xs={4} item>
                  <EstatePriceField {...commonParams} />
                </Grid>
                <Grid xs={4} item>
                  <CurrentStatusField {...commonParams} />
                </Grid>

                {estate.estateType === EstateTypeNameEnum.事業用 ? (
                  <>
                    <Grid xs={12} item>
                      <PurposeBsField {...commonParams} />
                    </Grid>
                  </>
                ) : null}

                {estate.estateType === EstateTypeNameEnum.土地 ||
                estate.estateType === EstateTypeNameEnum.分譲地 ? (
                  <>
                    <Grid xs={4} item>
                      <SquareOrTsuboAreaField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <GroundTypeField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <BldConditionTypeField {...commonParams} />
                    </Grid>
                  </>
                ) : estate.estateType === EstateTypeNameEnum.新築建売 ||
                  estate.estateType === EstateTypeNameEnum.中古戸建 ? (
                  <>
                    <Grid xs={4} item>
                      <CompletionMonthField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <SquareOrTsuboAreaField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <TotalAreaField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <LayoutField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <StructureField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <BuildingFloorsField {...commonParams} />
                    </Grid>
                  </>
                ) : estate.estateType === EstateTypeNameEnum.マンション ? (
                  <>
                    <Grid xs={12} item>
                      <MansionNameField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <SquareOrTsuboAreaField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <LayoutField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <FloorField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <RoomField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <ParkingField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <ManagementFeeField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <RepairCostField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <OtherCostField {...commonParams} />
                    </Grid>
                  </>
                ) : estate.estateType === EstateTypeNameEnum.事業用 ? (
                  <>
                    <Grid xs={4} item>
                      <CompletionMonthField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <SquareOrTsuboAreaField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <TotalAreaField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <StructureField {...commonParams} />
                    </Grid>
                    <Grid xs={4} item>
                      <BuildingFloorsForBusinessField {...commonParams} />
                    </Grid>
                  </>
                ) : null}

                <Grid xs={12} item>
                  <LocationField
                    estate={estate}
                    busy={busy}
                    location={location}
                    center={center}
                    zoom={zoom}
                    places={places}
                    onEstateChange={onEstateChange}
                    onAddressToMapButtonClick={onAddressToMapButtonClick}
                    onMapChange={onMapChange}
                    onMapReady={onMapReady}
                    onLocationChange={onLocationChange}
                  />
                </Grid>
                <Grid xs={12} item>
                  <RemarksField {...commonParams} />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <AttachesField
                    filesRef={filesRef}
                    humanFileSize={humanFileSize}
                  />
                </Grid>
              </Grid>
            </ThemeProvider>
          </DialogContent>
          <DialogActions className={styles.actions}>
            <Button
              variant="contained"
              className={styles.cancelButton}
              disabled={busy}
              onClick={props.onClose}
            >
              キャンセル
            </Button>
            <Button
              fullWidth
              className={styles.okButton}
              variant="contained"
              disabled={busy}
              onClick={onOkButtonClick}
            >
              {props.okButtonCaption}
            </Button>
          </DialogActions>
          <Box className={styles.bottomError}>{specifyMessage}</Box>
          <ProgressBackdrop open={busy || props.busy || false} />
          <Confirm
            open={confirm}
            content="登録依頼を送信します。"
            onClose={onConfirmClose}
            onCloseClick={onConfirmCloseClick}
            onOkClick={onConfirmClick}
          />
          <NoticeDialog
            open={thanks}
            content={
              <Box>
                ご登録ありがとうございます。
                <br />
                <br />
                ご提供いただいた物件情報はスタッフが確認し、掲載いたしますので、
                <br />
                今しばらくお待ちください。
                <br />
                <br />
                よろしくお願いいたします。
              </Box>
            }
            onClose={onThanksClose}
            onCloseClick={onThanksCloseClick}
          />
        </Dialog>
      );
    } else {
      return null;
    }
  }
);
