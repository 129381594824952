import { Box, Grid } from "@mui/material";
import { MainContext } from "common/interfaces/MainContext";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { HomeProps } from "../interfaces";
import { Info } from "../subComponents/Info";
import { Links } from "../subComponents/Links";
import { Logo } from "../subComponents/Logo";
import { Menu } from "../subComponents/Menu";
import styles from "./MiddleHomePageContent.module.scss";

export const MiddleHomePageContent = React.memo(
  (
    props: HomeProps & {
      mainContext: MainContext;
      navigate: NavigateFunction;
      onRequestInputDialogOpen: () => void;
    }
  ) => {
    const { mainContext, navigate } = props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className={styles.top}>
            <Logo styles={styles} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={styles.middle}>
            <Info styles={styles} mainContext={mainContext} />
            <Menu
              styles={styles}
              navigate={navigate}
              onRequestInputDialogOpen={props.onRequestInputDialogOpen}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={styles.bottom}>
            <Links
              styles={styles}
              navigate={navigate}
              mainContext={mainContext}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
);
