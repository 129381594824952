import { Box, Grid } from "@mui/material";
import { ReactComponent as ListSvg } from "assets/images/ic_top_list.svg";
import { ReactComponent as MapSvg } from "assets/images/ic_top_map.svg";
import { ReactComponent as PlusSvg } from "assets/images/ic_top_plus.svg";
import { ReactComponent as ShakeHandsSvg } from "assets/images/ic_top_shake_hands.svg";
import React from "react";
import { NavigateFunction } from "react-router-dom";

export const Menu = React.memo(
  (props: {
    styles: Record<string, string>;
    navigate: NavigateFunction;
    onRequestInputDialogOpen: () => void;
    forSmall?: boolean;
  }) => {
    const { styles, navigate } = props;
    const forLarge = !(props.forSmall ?? false);

    return (
      <Box className={styles.menu}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={forLarge ? 3 : 12}
            className={styles.menuButtonContainer}
          >
            <Box
              className={styles.menuButton}
              onClick={() => (window.location.href = "/search")}
            >
              <Box className={styles.menuIcon}>
                <MapSvg className={styles.menuIconSvg} />
              </Box>
              <Box className={styles.caption}>マップ</Box>
              {forLarge && <Box className={styles.remark}></Box>}
            </Box>
          </Grid>
          <Grid
            item
            xs={forLarge ? 3 : 12}
            className={styles.menuButtonContainer}
          >
            <Box
              className={styles.menuButton}
              onClick={() => navigate("check_estates")}
            >
              <Box className={styles.menuIcon}>
                <ListSvg className={styles.menuIconSvg} />
              </Box>
              <Box className={styles.caption}>物件一覧</Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={forLarge ? 3 : 12}
            className={styles.menuButtonContainer}
          >
            <Box
              className={styles.menuButton}
              onClick={() => navigate("check_estates_draft")}
            >
              <Box className={styles.menuIcon}>
                <ShakeHandsSvg className={styles.menuIconSvg} />
              </Box>
              <Box className={styles.caption}>
                ご協力を
                <br />
                お願いします
              </Box>
              {forLarge && (
                <Box className={styles.remark}>
                  貴社の物件について
                  <br />
                  位置を教えてください！
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={forLarge ? 3 : 12}
            className={styles.menuButtonContainer}
          >
            <Box
              className={styles.menuButton}
              onClick={props.onRequestInputDialogOpen}
            >
              <Box className={styles.menuIcon}>
                <PlusSvg className={styles.menuIconSvg} />
              </Box>
              <Box className={styles.caption}>
                物件登録
                <br />
                依頼
              </Box>
              {forLarge && (
                <Box className={styles.remark}>
                  物件をご登録ください
                  <br />
                  （掲載料無料）
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
);
